import { createSlice } from '@reduxjs/toolkit';

// Check if there's a saved theme preference in localStorage
const getSavedTheme = () => {
  try {
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      return savedTheme === 'dark';
    }
    // If no saved preference, default to light mode
    localStorage.setItem('theme', 'light'); // Set default to light and save it
    return false;
  } catch (error) {
    // Default to light mode if there's an error
    return false;
  }
};

const initialState = {
  isDarkMode: getSavedTheme(),
};

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    toggleTheme: (state) => {
      state.isDarkMode = !state.isDarkMode;
      // Save the preference to localStorage
      localStorage.setItem('theme', state.isDarkMode ? 'dark' : 'light');

      // Apply theme to document
      if (state.isDarkMode) {
        document.documentElement.classList.add('dark-mode');
      } else {
        document.documentElement.classList.remove('dark-mode');
      }
    },
    setTheme: (state, action) => {
      state.isDarkMode = action.payload;
      // Save the preference to localStorage
      localStorage.setItem('theme', state.isDarkMode ? 'dark' : 'light');

      // Apply theme to document
      if (state.isDarkMode) {
        document.documentElement.classList.add('dark-mode');
      } else {
        document.documentElement.classList.remove('dark-mode');
      }
    },
  },
});

export const { toggleTheme, setTheme } = themeSlice.actions;
export default themeSlice.reducer;