import React, { Suspense, useEffect } from "react";
import Layout from "./components/Layout/index";
import { Route, Routes } from "react-router-dom";
import { HelmetProvider } from 'react-helmet-async';
// Import Css
import "./assets/css/materialdesignicons.min.css"
import "./Apps.scss";
// import "./assets/css/colors/default.css";
// Include Routes
import routes from "./routes/allRoutes";
import withRouter from "./common/data/withRouter";
import { Provider, useSelector, useDispatch } from 'react-redux';
import { store } from './Redux/Store';
import { ToastContainer } from 'react-toastify';
import { setTheme } from './Redux/Slices/themeSlice';
import GithubCallback from './pages/GithubCallback';

// Theme initializer component
const ThemeInitializer = () => {
  const dispatch = useDispatch();
  const isDarkMode = useSelector((state) => state.theme.isDarkMode);

  useEffect(() => {
    // Check localStorage on component mount and set theme accordingly
    const savedTheme = localStorage.getItem('theme');
    if (savedTheme) {
      dispatch(setTheme(savedTheme === 'dark'));
    } else {
      // If no theme is saved, set to light theme
      localStorage.setItem('theme', 'light');
      dispatch(setTheme(false));
    }
  }, [dispatch]);

  useEffect(() => {
    // Apply theme class to document
    if (isDarkMode) {
      document.documentElement.classList.add('dark-mode');
    } else {
      document.documentElement.classList.remove('dark-mode');
    }
  }, [isDarkMode]);

  return null;
};

const App = () => {
  const LoaderComponent = () => (
    <div id="">
      <div id="status">
        <div className="spinner">
          <div className="double-bounce1"></div>
          <div className="double-bounce2"></div>
        </div>
      </div>
    </div>
  );

  return (
    <React.Fragment>
      <Provider store={store}>
        <HelmetProvider>
          <ThemeInitializer />
          <Suspense fallback={<LoaderComponent />}>
            <Routes>
              {routes.map((route, idx) =>
                route.isWithoutLayout ? (
                  <Route
                    path={route.path}
                    element={route.component}
                    key={idx} />
                ) : (
                  <Route
                    path={route.path}
                    element={
                      <Layout hasDarkTopBar={route.isTopbarDark}>
                        {route.component}
                      </Layout>
                      // withLayout(route.component, route.isTopbarDark)
                    }
                    key={idx}
                  />
                )
              )}
              <Route path="/github-callback" element={<GithubCallback />} />
            </Routes>
          </Suspense>
          <ToastContainer />
        </HelmetProvider>
      </Provider>
    </React.Fragment>
  );
}

export default withRouter(App);
