import React from 'react';
import { Helmet } from 'react-helmet-async';

const CourseSchema = () => {
  return (
    <Helmet>
      {/* Structured Data - Course */}
      <script type="application/ld+json">
        {JSON.stringify([
          {
            "@context": "https://schema.org",
            "@type": "Course",
            "name": "Javascript & NodeJS",
            "description": "The perfect blend to build scalable systems.",
            "provider": {
              "@type": "Organization",
              "name": "CodesWithVic",
              "sameAs": "https://codeswithvic.com/"
            },
            "offers": {
              "@type": "Offer",
              "price": "50",
              "priceCurrency": "USD",
              "availability": "https://schema.org/InStock",
              "url": "https://codeswithvic.com/courses/javascript-nodejs"
            },
            "hasCourseInstance": [
              {
                "@type": "CourseInstance",
                "name": "Javascript & NodeJS",
                "courseMode": "online",
                "startDate": "2024-01-01",
                "location": {
                  "@type": "VirtualLocation",
                  "url": "https://codeswithvic.com/courses/javascript-nodejs"
                }
              }
            ]
          },
          {
            "@context": "https://schema.org",
            "@type": "Course",
            "name": "React & Redux",
            "description": "The perfect blend to build scalable frontend systems.",
            "provider": {
              "@type": "Organization",
              "name": "CodesWithVic",
              "sameAs": "https://codeswithvic.com/"
            },
            "offers": {
              "@type": "Offer",
              "price": "50",
              "priceCurrency": "USD",
              "availability": "https://schema.org/InStock",
              "url": "https://codeswithvic.com/courses/react-redux"
            },
            "hasCourseInstance": [
              {
                "@type": "CourseInstance",
                "name": "React & Redux",
                "courseMode": "online",
                "startDate": "2024-01-01",
                "location": {
                  "@type": "VirtualLocation",
                  "url": "https://codeswithvic.com/courses/react-redux"
                }
              }
            ]
          },
          {
            "@context": "https://schema.org",
            "@type": "Course",
            "name": "Go, Gin & Echo",
            "description": "The perfect blend to build scalable systems.",
            "provider": {
              "@type": "Organization",
              "name": "CodesWithVic",
              "sameAs": "https://codeswithvic.com/"
            },
            "offers": {
              "@type": "Offer",
              "price": "60",
              "priceCurrency": "USD",
              "availability": "https://schema.org/InStock",
              "url": "https://codeswithvic.com/courses/go-gin-echo"
            },
            "hasCourseInstance": [
              {
                "@type": "CourseInstance",
                "name": "Go, Gin & Echo",
                "courseMode": "online",
                "startDate": "2024-01-01",
                "location": {
                  "@type": "VirtualLocation",
                  "url": "https://codeswithvic.com/courses/go-gin-echo"
                }
              }
            ]
          },
          {
            "@context": "https://schema.org",
            "@type": "Course",
            "name": "Python & Django",
            "description": "The perfect blend to build scalable systems.",
            "provider": {
              "@type": "Organization",
              "name": "CodesWithVic",
              "sameAs": "https://codeswithvic.com/"
            },
            "offers": {
              "@type": "Offer",
              "price": "50",
              "priceCurrency": "USD",
              "availability": "https://schema.org/InStock",
              "url": "https://codeswithvic.com/courses/python-django"
            },
            "hasCourseInstance": [
              {
                "@type": "CourseInstance",
                "name": "Python & Django",
                "courseMode": "online",
                "startDate": "2024-01-01",
                "location": {
                  "@type": "VirtualLocation",
                  "url": "https://codeswithvic.com/courses/python-django"
                }
              }
            ]
          }
        ])}
      </script>
    </Helmet>
  );
};

export default CourseSchema;