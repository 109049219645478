import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Row,
  Col,
  Alert,
  Modal,
  ModalBody,
  Card,
  CardBody,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
  ModalFooter,
  Popover,
  PopoverBody,
  Badge
} from "reactstrap";
import { addItem } from "../../Redux/Slices/cartSlice";
import {
  getCachedContent,
  setCacheContent,
  currencySymbol
} from "../../util/helper";
import FeatherIcon from "feather-icons-react";
import axios from "axios";
import golang from "../../assets/images/course/golang1.svg";
import react from "../../assets/images/course/react.svg";
import python from "../../assets/images/course/python.svg";
import csharp from "../../assets/images/course/csharp.svg";
import nodejs from "../../assets/images/course/nodejs.svg";
import machine from "../../assets/images/course/machine.svg";
import java from "../../assets/images/course/java.svg";
import dataanalysis from "../../assets/images/course/dataanalysis.svg";
import cybersecurity from "../../assets/images/course/cybersecurity.svg";

import { fetchIp } from "../../util/geohelper";

const getRandomNumber = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;


const levelSwap = {
  1: "Novice",
  2: "Intermediate",
  3: "Senior",
  4: "Tech Lead",
  5: "Architect"
};
const CourseCard = React.memo(({ course, handleCardMouseEnter, handleCardMouseLeave, handleCourseDetail, handleAddToCart }) => {
  const randomStudents = useMemo(() => getRandomNumber(30, 600), []);
  const randomLessons = useMemo(() => getRandomNumber(15, 40), []);
  const randomStars = useMemo(() => getRandomNumber(3, 5), []);

  const getImage = (course) => {
    switch (course.name) {
      case "Go, Gin & Echo":
        return golang;
      case "Machine Learning":
        return machine;
      case "Java":
        return java;
      case "Data Analysis":
        return dataanalysis;
      case "Cyber Security":
        return cybersecurity;
      case "React & Redux":
        return react;
      case "Python & Django":
        return python;
      case "C# & ASP Core":
        return csharp;
      case "Javascript & NodeJS":
        return nodejs;
      default:
        return golang;
    }
  }

  return (
    <Card
      className="blog blog-primary overflow-hidden rounded shadow border-0"
      id={`course-${course.id}`}
      onMouseEnter={() => handleCardMouseEnter(course.id)}
      onMouseLeave={() => handleCardMouseLeave(course.id)}
    >
      <div className="position-relative d-block overflow-hidden">
        <img src={getImage(course)} className="img-fluid rounded-top mx-auto" alt="" />
        <div className="overlay-work bg-dark"></div>
        <div onClick={() => handleCourseDetail(course)} className="h6 preview bottom-0 end-0 me-4 mb-3">
          Preview Now <i className="uil uil-angle-right-b align-middle"></i>
        </div>
      </div>
      <CardBody className="content">
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="mb-0">
            <a onClick={() => handleCourseDetail(course)} className="title text-dark h5" style={{ cursor: "pointer", fontSize: "1rem", lineHeight: "1.4" }}>
              {course.name}
            </a>
          </h5>
          <div className="btn btn-icon btn-pills btn-primary shoppingbtn" onClick={() => handleAddToCart(course)}>
            <FeatherIcon icon="shopping-cart" className="fea icon-sm" />
          </div>
        </div>
        <div className="rating mt-2">
          <ul className="list-unstyled mb-0">
            {Array(4).fill().map((_, i) => (
              <li key={i} className="list-inline-item">
                <i className="mdi mdi-star h6 mb-0 text-warning"></i>
              </li>
            ))}
            <li className="list-inline-item">
              {randomStars} Star (3 <i className="uil uil-user text-muted small"></i>)
            </li>
          </ul>
        </div>
        <div className="fees d-flex justify-content-between align-items-center">
          <ul className="list-unstyled mb-0 numbers">
            <li>
              <i className="uil uil-graduation-cap text-muted"></i>
              {randomStudents} Students
            </li>
            <li>
              <i className="uil uil-notebooks text-muted"></i>
              {randomLessons} Lessons
            </li>
          </ul>
          <h4 className="mb-0">
            <span className="h6">{currencySymbol(course.currency)}</span>
            {course.isOnDiscount ? course.discountedPrice : course.price}
          </h4>
        </div>
      </CardBody>
    </Card>
  );
});

CourseCard.displayName = 'CourseCard';

const CourseBox = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [courses, setCourses] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [coursePreview, setCoursePreview] = useState(null);
  const [open, setOpen] = useState('1');
  const [activePopover, setActivePopover] = useState(null);
  const [isMouseOverCard, setIsMouseOverCard] = useState({});
  const [isMouseOverPopover, setIsMouseOverPopover] = useState({});
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Check on initial load

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const fetchCourses = useCallback(async () => {
    let country = getCachedContent("country");
    if (!country) {
      await fetchIp();
    }
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/courses?location=${getCachedContent("country")}`);
      setCourses(response.data);
    } catch (error) {
      console.error("Error fetching courses:", error);
    }
  }, []);

  useEffect(() => {
    fetchCourses();
  }, [fetchCourses]);

  const handleAddToCart = useCallback((course) => {
    setSelectedCourse(course);
    dispatch(addItem(course));
    setTimeout(() => {
      setSelectedCourse(null);
    }, 2700);
  }, [dispatch]);

  const toggle = useCallback((id) => setOpen((prev) => (prev === id ? undefined : id)), []);

  const handleCourseDetail = useCallback(async (course) => {
    try {
      const previewFrom = getCachedContent(`coursePreview-${course.id}`);
      if (!previewFrom) {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/courses/preview/${course.id}`);
        if (response.status === 200) {
          setCoursePreview(response.data);
          setCacheContent(`coursePreview-${course.id}`, response.data);
          setShowModal(true);
        }
      } else {
        setCoursePreview(previewFrom);
        setShowModal(true);
      }
    } catch (error) {
      console.error("Error getting content:", error);
    }
  }, []);

  const cleanupPopovers = useCallback(() => {
    const shouldClosePopover = activePopover !== null &&
      !isMouseOverCard[activePopover] &&
      !isMouseOverPopover[activePopover];

    if (shouldClosePopover) {
      setActivePopover(null);
    }
  }, [activePopover, isMouseOverCard, isMouseOverPopover]);

  useEffect(() => {
    const cleanupInterval = setInterval(cleanupPopovers, 200);
    return () => clearInterval(cleanupInterval);
  }, [cleanupPopovers]);

  const handleCardMouseEnter = useCallback((courseId) => {
    if (!isMobile) {
      setIsMouseOverCard(prevState => ({ ...prevState, [courseId]: true }));
      setActivePopover(courseId);
    }
  }, [isMobile]);

  const handleCardMouseLeave = useCallback((courseId) => {
    setIsMouseOverCard(prevState => ({ ...prevState, [courseId]: false }));
  }, []);

  const handlePopoverMouseEnter = useCallback((courseId) => {
    setIsMouseOverPopover(prevState => ({ ...prevState, [courseId]: true }));
  }, []);

  const handlePopoverMouseLeave = useCallback((courseId) => {
    setIsMouseOverPopover(prevState => ({ ...prevState, [courseId]: false }));
  }, []);

  const monthYear = useCallback((date) => {
    return `${new Date(date).toLocaleString('default', { month: 'long' })} ${new Date(date).getFullYear()}`;
  }, []);

  const renderPopoverContent = useCallback((course) => (
    <div
      className="course-popover"
      onMouseEnter={() => handlePopoverMouseEnter(course.id)}
      onMouseLeave={() => handlePopoverMouseLeave(course.id)}
    >
      <h4 className="mb-2">{course.name || 'Untitled Course'}</h4>
      {course.bestseller || 1 && <Badge color="warning" className="mb-2">Bestseller</Badge>}
      {course.lastUpdated && <p className="text-warning mb-1"><strong className="text-primary">Updated: </strong> {monthYear(course.lastUpdated)} </p>}
      <p className="text-secondary mb-2">
        {course.totalHours && `${course.totalHours } total hours`}
        {course.level  && ` · ${course.level || 'All levels'}`}
        {course.subtitles !== undefined && ` · ${course.subtitles ? 'Subtitles' : 'No subtitles'}`}
      </p>
      {course.description && <p className="mb-2">{course.description}</p>}
      <ul className="list-unstyled mb-3">
        {course.projectCount && (
          <li className="mb-1"><FeatherIcon icon="check" size="16" /> {course.projectCount} development projects for your portfolio</li>
        )}
        {course.technologies && course.technologies.length > 0 && (
          <li className="mb-1"><FeatherIcon icon="check" size="16" /> Technologies included
          <small className="text-danger">{ ' '+course.technologies}</small></li>
        )}
        <li className="mb-1"><FeatherIcon icon="check" size="16" /> {course.name !== ('Data Analysis' || 'Cyber Security' ) ? 'Through this course you will build ANY web app' : 'Become an expoert and create value' }.</li>
      </ul>
      <button
        className="btn btn-primary btn-block mt-1 w-100"
        onClick={() => handleAddToCart(course)}
      >
        Add to cart
      </button>
    </div>
  ), [handlePopoverMouseEnter, handlePopoverMouseLeave, handleAddToCart, monthYear]);

  const getCoursePreview = useMemo(() => {
    if (!coursePreview) return null;

    return (
      <>
        <Row>
          <Col className="text-center">
            <h3 className="mb-3">{coursePreview.name}</h3>
            <p className="mb-4">{coursePreview.description}</p>
          </Col>
        </Row>
        <Accordion open={open} toggle={toggle}>
          {Object.entries(coursePreview.contents).map(([level, contentsArray]) => (
            <AccordionItem className="rounded mb-2" key={level} expanded={true}>
              <AccordionHeader targetId={level}>
                <h5 className="mb-0">{levelSwap[level]}</h5>
              </AccordionHeader>
              <AccordionBody accordionId={level}>
                {contentsArray.map((content, key) => (
                  <div key={key} className="mb-3">
                    <Row className="align-items-center">
                      <Col lg="10" md="9" xs="12">
                        <p className="mb-2">{content.description}</p>
                      </Col>
                      <Col lg="2" md="3" xs="12">
                        <p className="mb-0">Duration: {content.duration}</p>
                      </Col>
                    </Row>
                  </div>
                ))}
              </AccordionBody>
            </AccordionItem>
          ))}
        </Accordion>
      </>
    );
  }, [coursePreview, open, toggle]);

  const memoizedCourses = useMemo(() => courses.map((course, key) => (
    <Col lg="4" md="6" xs="12" key={key} className="mt-4 pt-2">
      <CourseCard
        course={course}
        handleCardMouseEnter={handleCardMouseEnter}
        handleCardMouseLeave={handleCardMouseLeave}
        handleCourseDetail={handleCourseDetail}
        handleAddToCart={handleAddToCart}
      />
      {!isMobile && (
        <Popover
          placement="right"
          isOpen={activePopover === course.id}
          target={`course-${course.id}`}
          toggle={() => setActivePopover(activePopover === course.id ? null : course.id)}
        >
          <PopoverBody>
            {renderPopoverContent(course)}
          </PopoverBody>
        </Popover>
      )}
    </Col>
  )), [courses, handleCardMouseEnter, handleCardMouseLeave, handleCourseDetail, handleAddToCart, activePopover, renderPopoverContent, isMobile]);

  return (
    <>
      <Alert className="bg-soft-primary fw-medium text-center" color="none" isOpen={!!selectedCourse}>
        {selectedCourse?.name ? `${selectedCourse.name} has been added to Cart` : ''}
      </Alert>
      {memoizedCourses}
      <Modal isOpen={showModal} toggle={() => setShowModal(prev => !prev)} centered size="xl">
        <ModalBody>
          <div>{getCoursePreview}</div>
        </ModalBody>
        <ModalFooter style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <button type="button" onClick={() => setShowModal(false)} className="btn btn-soft-primary text">
            Thanks
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default CourseBox;