import React, { useState, useEffect, useRef } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Card, CardBody, Col, Container, Form, Input, Label, Row, FormFeedback, FormGroup, Button, Badge, Modal, ModalHeader, ModalBody, Toast, ToastHeader, ToastBody } from 'reactstrap';
import Select from 'react-select';

// Modal Video
import ModalVideo from "react-modal-video";
import "../../../node_modules/react-modal-video/scss/modal-video.scss";

//Import Icons
import FeatherIcon from "feather-icons-react";

import bg1 from '../../assets/images/it/bg1.png';

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import { addItem, removeItem } from "../../Redux/Slices/cartSlice";
import axios from "axios";
import { getCachedContent, setCacheContent, currencySymbol } from "../../util/helper";

// Payment Components
import PaymentModal from "../../components/PaymentModal";
import { loadStripe } from '@stripe/stripe-js';

const Section = () => {
    const [open, setOpen] = useState(false);
    const [courses, setCourses] = useState([]);
    const [selectedCourses, setSelectedCourses] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [showPaymentModal, setShowPaymentModal] = useState(false);
    const [isGoogleLoading, setIsGoogleLoading] = useState(false);
    const [isGithubLoading, setIsGithubLoading] = useState(false);
    const [stripePromise, setStripePromise] = useState(null);
    const [userFormData, setUserFormData] = useState(null);
    const [authError, setAuthError] = useState(null);
    const [errorTimer, setErrorTimer] = useState(null);
    const [showSuccessToast, setShowSuccessToast] = useState(false);
    const [toastMessage, setToastMessage] = useState('');
    const dispatch = useDispatch();
    const navigate = useNavigate();

    // Get selected courses from Redux store
    const syncedSelectedCourses = useSelector((state) => state.selectedCourses || []);

    // Get cart items from Redux store
    const cartItems = useSelector((state) => state.cart.items || []);

    // Update useEffect to sync courses when they change in cart
    useEffect(() => {
        if (syncedSelectedCourses.length > 0) {
            setSelectedCourses(syncedSelectedCourses);
        }
    }, [syncedSelectedCourses]);

    // Listen for changes in the cart and update selected courses accordingly
    useEffect(() => {
        if (selectedCourses.length > 0 && cartItems) {
            // Get IDs of items currently in the cart
            const cartItemIds = cartItems.map(item => item.id);

            // Filter selected courses to only include those still in the cart
            const updatedSelectedCourses = selectedCourses.filter(
                course => cartItemIds.includes(course.value.id)
            );

            // Only update if there's a difference (something was removed from cart)
            if (updatedSelectedCourses.length !== selectedCourses.length) {
                setSelectedCourses(updatedSelectedCourses);
            }
        }
    }, [cartItems]);

    // Create refs for the Google and GitHub buttons
    const googleButtonRef = useRef(null);
    const githubButtonRef = useRef(null);

    // Expose functions to trigger the buttons
    useEffect(() => {
        // Create global functions that can be called from other components
        window.triggerGoogleLogin = () => {
            if (selectedCourses.length === 0) {
                setTemporaryAuthError("Please select at least one course first");
                return;
            }
            handleGoogleLogin();
        };

        window.triggerGithubLogin = () => {
            if (selectedCourses.length === 0) {
                setTemporaryAuthError("Please select at least one course first");
                return;
            }
            handleGithubLogin();
        };

        return () => {
            // Clean up
            delete window.triggerGoogleLogin;
            delete window.triggerGithubLogin;
        };
    }, [selectedCourses]);

    useEffect(() => {
        fetchCourses();

        // Load Stripe
        fetch(`${process.env.REACT_APP_BASE_URL}/courses/config`).then(async (r) => {
            const { publishableKey } = await r.json();
            setStripePromise(loadStripe(publishableKey));
        });

        // Load Google API
        //loadGoogleAPI();
    }, []);

    // const loadGoogleAPI = () => {
    //     // Load the Google API script
    //     const script = document.createElement('script');
    //     script.src = 'https://apis.google.com/js/platform.js';
    //     script.async = true;
    //     script.defer = true;
    //     script.onload = initGoogleAuth;
    //     document.head.appendChild(script);
    // };

    // const initGoogleAuth = () => {
    //     // Initialize Google Auth once the API is loaded
    //     window.gapi.load('auth2', () => {
    //         window.gapi.auth2.init({
    //             client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    //         });
    //     });
    // };

    const fetchCourses = async () => {
        let country = getCachedContent("country");
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/courses?location=${country || ''}`);
            setCourses(response.data);
        } catch (error) {
            console.error("Error fetching courses:", error);
        }
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            firstname: "",
            email: "",
        },
        validationSchema: Yup.object({
            firstname: Yup.string().required("Name is required"),
            email: Yup.string().email("Invalid email format").required("Email is required"),
        }),
        onSubmit: (values) => {
            handleSubmitForm(values);
        }
    });

    const handleCourseSelection = (selectedOptions) => {
        // Get the current selected courses before updating
        const previousSelectedCourses = [...selectedCourses];

        // Update the selected courses
        setSelectedCourses(selectedOptions || []);

        // Find courses that were removed
        const currentSelectedIds = (selectedOptions || []).map(option => option.value.id);
        const removedCourses = previousSelectedCourses.filter(
            course => !currentSelectedIds.includes(course.value.id)
        );

        // Remove each removed course from the cart
        removedCourses.forEach(course => {
            dispatch(removeItem(course.value));
        });

        // Add any newly selected courses to the cart
        if (selectedOptions) {
            const previousSelectedIds = previousSelectedCourses.map(course => course.value.id);
            const newlySelectedCourses = selectedOptions.filter(
                option => !previousSelectedIds.includes(option.value.id)
            );

            newlySelectedCourses.forEach(course => {
                dispatch(addItem(course.value));
            });
        }
    };

    const handleSubmitForm = (values) => {
        setIsSubmitting(true);

        try {
            // Store form data for later registration
            setUserFormData({
                name: values.firstname,
                email: values.email,
            });

            // Add selected courses to cart
            selectedCourses.forEach(course => {
                dispatch(addItem(course.value));
            });

            // Show payment modal
            setShowPaymentModal(true);
        } catch (error) {
            console.error("Error processing form:", error);
            alert("There was an error processing your request. Please try again.");
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleButtonClick = (e, link) => {
        e.preventDefault();
        const sectionId = link.replace("#", "");
        // Implement direct scroll functionality
        const element = document.getElementById(sectionId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const togglePaymentModal = () => {
        setShowPaymentModal(!showPaymentModal);
    };

    // Function to set auth error with auto-reset after 15 seconds
    const setTemporaryAuthError = (errorMessage) => {
        // Clear any existing timer
        if (errorTimer) {
            clearTimeout(errorTimer);
        }

        // Set the error message
        setAuthError(errorMessage);

        // Set a new timer to clear the error after 6 seconds
        const timer = setTimeout(() => {
            setAuthError(null);
        }, 6000); // 6 seconds

        // Store the timer ID so we can clear it if needed
        setErrorTimer(timer);
    };

    // Clean up timer on component unmount
    useEffect(() => {
        return () => {
            if (errorTimer) {
                clearTimeout(errorTimer);
            }
        };
    }, [errorTimer]);

    const handleGoogleLogin = () => {
        setIsGoogleLoading(true);
        setAuthError(null);

        // Make sure you have the client_id in your .env file
        const googleAuthUrl = new URL(`${process.env.REACT_APP_BASE_URL}/auth/google`);
        googleAuthUrl.searchParams.append('x-source', 'registration');

        const width = 600;
        const height = 700;
        const left = window.screen.width / 2 - width / 2;
        const top = window.screen.height / 2 - height / 2;

        const popup = window.open(
            googleAuthUrl.toString(),
            'google-oauth',
            `width=${width},height=${height},left=${left},top=${top}`
        );

        if (!popup || popup.closed || typeof popup.closed === 'undefined') {
            setTemporaryAuthError("Popup blocked! Please allow popups for this site.");
            setIsGoogleLoading(false);
            return;
        }

        // Listen for messages from the popup
        const messageHandler = (event) => {
            // Ensure the message comes from our domain
            if (event.origin !== window.location.origin) return;

            const { token } = event.data;
            if (token) {
                processToken(token);
            } else {
                // Only show error if payment modal is not already open
                if (!showPaymentModal) {
                    setTemporaryAuthError("No token received from Google login");
                }
                popup.close();
                setIsGoogleLoading(false);
            }

            // Cleanup event listener
            window.removeEventListener("message", messageHandler);
            clearInterval(tokenCheckInterval);
        };

        // Function to process the token
        const processToken = (token) => {
            try {
                const base64Url = token.split('.')[1];
                const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                const jsonPayload = decodeURIComponent(atob(base64).split('').map(c =>
                    '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
                ).join(''));

                const userData = JSON.parse(jsonPayload);

                // Extract user ID from token
                const userId = userData.id;

                validation.setValues({
                    ...validation.values,
                    firstname: userData.name,
                    email: userData.email
                });

                setUserFormData({
                    name: userData.name,
                    email: userData.email,
                    token: token,
                    userId: userId // Store the user ID
                });

                // Add selected courses to cart
                selectedCourses.forEach(course => {
                    dispatch(addItem(course.value));
                });

                // Show payment modal immediately
                setShowPaymentModal(true);

                if (!popup.closed) {
                    popup.close();
                }
                setIsGoogleLoading(false);
            } catch (error) {
                console.error("Error parsing token:", error);
                // Only show error if payment modal is not already open
                if (!showPaymentModal) {
                    setTemporaryAuthError("Failed to process login data");
                }
                if (!popup.closed) {
                    popup.close();
                }
                setIsGoogleLoading(false);
            }
        };

        window.addEventListener("message", messageHandler);

        // Check for token in localStorage
        const checkToken = () => {
            const token = localStorage.getItem("authToken");
            if (token) {
                localStorage.removeItem("authToken"); // Remove after reading
                processToken(token);
                clearInterval(tokenCheckInterval);
                window.removeEventListener("message", messageHandler);
            }
        };

        // Set a timeout to check for token every 1 second
        const tokenCheckInterval = setInterval(checkToken, 1000);

        // Set a timeout to close popup and remove listener after 1 minute
        setTimeout(() => {
            if (!popup.closed) {
                popup.close();
            }
            setIsGoogleLoading(false);
            // Only show error if payment modal is not already open
            if (!showPaymentModal) {
                setTemporaryAuthError("Google login timed out. Please try again.");
            }
            window.removeEventListener("message", messageHandler);
            clearInterval(tokenCheckInterval);
        }, 60000);
    };

    const handleGithubLogin = () => {
        setIsGithubLoading(true);
        setAuthError(null);

        const githubAuthUrl = new URL(`${process.env.REACT_APP_BASE_URL}/auth/github`);
        githubAuthUrl.searchParams.append('x-source', 'registration');

        const width = 600;
        const height = 700;
        const left = window.screen.width / 2 - width / 2;
        const top = window.screen.height / 2 - height / 2;

        const popup = window.open(
            githubAuthUrl.toString(),
            'github-oauth',
            `width=${width},height=${height},left=${left},top=${top}`
        );

        if (!popup || popup.closed || typeof popup.closed === 'undefined') {
            setTemporaryAuthError("Popup blocked! Please allow popups for this site.");
            setIsGithubLoading(false);
            return;
        }

        // Listen for messages from the popup
        const messageHandler = (event) => {
            // Ensure the message comes from our domain
            if (event.origin !== window.location.origin) return;

            const { token } = event.data;
            if (token) {
                processToken(token);
            } else {
                // Only show error if payment modal is not already open
                if (!showPaymentModal) {
                    setTemporaryAuthError("No token received from GitHub login");
                }
                popup.close();
                setIsGithubLoading(false);
            }

            // Cleanup event listener
            window.removeEventListener("message", messageHandler);
            clearInterval(tokenCheckInterval);
        };

        // Function to process the token
        const processToken = (token) => {
            try {
                const base64Url = token.split('.')[1];
                const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
                const jsonPayload = decodeURIComponent(atob(base64).split('').map(c =>
                    '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
                ).join(''));

                const userData = JSON.parse(jsonPayload);

                // Extract user ID from token
                const userId = userData.id;

                validation.setValues({
                    ...validation.values,
                    firstname: userData.name,
                    email: userData.email
                });

                setUserFormData({
                    name: userData.name,
                    email: userData.email,
                    token: token,
                    userId: userId // Store the user ID
                });

                // Add selected courses to cart
                selectedCourses.forEach(course => {
                    dispatch(addItem(course.value));
                });

                // Show payment modal immediately
                setShowPaymentModal(true);

                if (!popup.closed) {
                    popup.close();
                }
                setIsGithubLoading(false);
            } catch (error) {
                console.error("Error parsing token:", error);
                // Only show error if payment modal is not already open
                if (!showPaymentModal) {
                    setTemporaryAuthError("Failed to process login data");
                }
                if (!popup.closed) {
                    popup.close();
                }
                setIsGithubLoading(false);
            }
        };

        window.addEventListener("message", messageHandler);

        // Check for token in localStorage
        const checkToken = () => {
            const token = localStorage.getItem("authToken");
            if (token) {
                localStorage.removeItem("authToken"); // Remove after reading
                processToken(token);
                window.removeEventListener("message", messageHandler);
            }
        };

        // Set a timeout to check for token every 1 second
        const tokenCheckInterval = setInterval(checkToken, 1000);

        // Set a timeout to close popup and remove listener after 1 minute
        setTimeout(() => {
            if (!popup.closed) {
                popup.close();
            }
            setIsGithubLoading(false);
            // Only show error if payment modal is not already open
            if (!showPaymentModal) {
                setTemporaryAuthError("GitHub login timed out. Please try again.");
            }
            window.removeEventListener("message", messageHandler);
            clearInterval(tokenCheckInterval);
        }, 60000);
    };

    const handlePaymentSuccess = async (reference) => {
        console.log("Payment successful:", reference);

        try {
            let userId, jwt;

            // If we already have a token from OAuth, use it
            if (userFormData?.token) {
                jwt = userFormData.token;
                userId = userFormData.userId;
            } else {
                // Register the user if we don't have a token
                const userResponse = await axios.post(`${process.env.REACT_APP_BASE_URL}/users/register`, userFormData);
                userId = userResponse.data.id;
                jwt = userResponse.data.token;
            }

            // Store user details
            setCacheContent('userdetails', {
                userWithoutPassword: {
                    id: userId,
                    name: userFormData.name,
                    email: userFormData.email,
                }
            });

            // Register courses for the user
            await axios.post(
                `${process.env.REACT_APP_BASE_URL}/subscriptions`,
                {
                    userId: userId,
                    courseIds: selectedCourses.map(course => course.value.id),
                    amountPaid: reference.amount,
                    paymentTransactionID: reference.transaction_id,
                    paymentCurrency: reference.currency,
                    flw_ref: reference.flw_ref,
                    tx_ref: reference.tx_ref,
                    paymentStatus: reference.status,
                    paymentChannel: reference.paymentChannel || 'Unknown',
                },
                {
                    headers: {
                        Authorization: `Bearer ${jwt}`,
                    },
                }
            );

            // Show success notification instead of alert
            setToastMessage("Registration successful! You will be redirected to your courses.");
            setShowSuccessToast(true);

            // Redirect after a short delay
            setTimeout(() => {
                const baseUrlInternal = process.env.REACT_APP_BASE_URL_INTERNAL;
                window.location.href = `${baseUrlInternal}?jwt=${jwt}`;
            }, 3000); // 3 seconds delay to show the notification

        } catch (error) {
            console.error("Error during registration after payment:", error);
            setToastMessage("Payment was successful, but there was an error completing your registration. Please contact support.");
            setShowSuccessToast(true);
        }
    };

    // Format courses for react-select
    const courseOptions = courses.map(course => ({
        value: course,
        label: `${course.name} - $${course.isOnDiscount ? course.discountedPrice : course.price}`
    }));

    const totalAmount = selectedCourses.reduce((sum, option) => sum + parseFloat(option.value.isOnDiscount ? option.value.discountedPrice : option.value.price || 0), 0);

    // Custom styles for react-select
    const customStyles = {
        control: (provided) => ({
            ...provided,
            borderColor: '#ced4da',
            boxShadow: 'none',
            '&:hover': {
                borderColor: '#86b7fe',
            }
        }),
        multiValue: (provided) => ({
            ...provided,
            backgroundColor: '#f8f9fa',
        }),
        multiValueLabel: (provided) => ({
            ...provided,
            color: '#495057',
        }),
        multiValueRemove: (provided) => ({
            ...provided,
            color: '#495057',
            '&:hover': {
                backgroundColor: '#e9ecef',
                color: '#000',
            },
        }),
    };

    // Custom format option for react-select
    const formatOptionLabel = ({ value, label }) => (
        <div className="d-flex justify-content-between align-items-center">
            <div>{value.name}</div>
            <div className="text-primary font-weight-bold">{currencySymbol(value.currency)}{value.isOnDiscount ? value.discountedPrice : value.price}</div>
        </div>
    );

    // Function to open modal from Footer component using a custom event
    const openFooterModal = (content) => {
        // Create and dispatch a custom event that Footer.js can listen for
        const event = new CustomEvent('openFooterModal', {
            detail: { content }
        });
        window.dispatchEvent(event);
    };

    const openExternalLink = (event) => {
        event.preventDefault();

        const vscodeURL = "vscode://extension/CodesWithVic.cwvhelp";
        const fallbackURL = "https://marketplace.visualstudio.com/items?itemName=CodesWithVic.cwvhelp";

        window.open(vscodeURL, "_blank");

        setTimeout(() => {
          const proceed = window.confirm("Did VS Code open?");
          if (!proceed) {
            window.open(fallbackURL, "_blank");
          }
        }, 3000);
      };

    return (
        <React.Fragment>
            <section className="bg-half-170 d-table w-100 it-home"
                style={{ background: `url(${bg1}) center center` }}
            >
                <div className="bg-overlay opacity-10"></div>
                <Container>
                    <Row className="align-items-center">
                        <Col lg={7} md={6} xs={12}>
                            <div className="title-heading">
                                <h6 className="text-white-50">No worries about internet access</h6>
                                <h1 className="mt-2 mb-3 text-white fw-bold title-dark">Master Tech Skills Offline  <br /> with our <a href="#" onClick={openExternalLink} rel="noreferrer" target="_blank">VS Code Extension</a>.</h1>
                                <p className="para-desc text-white-50">Build Projects Offline, Gain Skills, and Boost Your Career.</p>
                                <div className="pt-2 mt-4">
                                    <Link className="m-1 btn btn-primary"
                                    to={"#courses"}
                                    onClick={(e) => handleButtonClick(e, "#courses")}
                                    > Discover Courses & Enroll Now</Link>{" "}
                                </div>
                            </div>
                        </Col>

                        <Col lg={5} md={6} className="pt-2 mt-4 mt-sm-0 pt-sm-0">
                            <Card className="border-0 rounded shadow ms-lg-5">
                                <CardBody>
                                    <h5 className="card-title fw-bold">Choose Your Courses and Get Started</h5>
                                    <Form
                                        onSubmit={(e) => {
                                            e.preventDefault();
                                            validation.handleSubmit();
                                            return false;
                                        }}
                                        className="login-form">
                                        <Row>
                                            <Col md={12}>
                                                <div className="mb-3">
                                                    <Label className="form-label">Available Courses <span className="text-danger">*</span></Label>
                                                    <Select
                                                        isMulti
                                                        name="courses"
                                                        options={courseOptions}
                                                        className="basic-multi-select"
                                                        classNamePrefix="select"
                                                        placeholder="Explore Coding, Data Science, E.t.c"
                                                        value={selectedCourses}
                                                        onChange={handleCourseSelection}
                                                        styles={customStyles}
                                                        formatOptionLabel={formatOptionLabel}
                                                    />
                                                    {selectedCourses.length > 0 && (
                                                        <div className="p-3 mt-2 rounded bg-light">
                                                            <div className="d-flex justify-content-between">
                                                                <span>Total:</span>
                                                                <strong>{currencySymbol(selectedCourses[0]?.value?.currency || 'USD')}{totalAmount.toFixed(2)}</strong>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </Col>

                                            {/* Terms of Use text moved up here */}
                                            <Col md={12}>
                                                <div className="mb-3 text-center">
                                                    <p className="mb-0 text-muted small">
                                                        By continuing, you agree to our{" "}
                                                        <Link
                                                            to="#"
                                                            className="text-primary"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                openFooterModal("Terms & Conditions");
                                                            }}
                                                        >
                                                            Terms
                                                        </Link>{" "}
                                                        &{" "}
                                                        <Link
                                                            to="#"
                                                            className="text-primary"
                                                            onClick={(e) => {
                                                                e.preventDefault();
                                                                openFooterModal("Privacy Policy");
                                                            }}
                                                        >
                                                            Privacy Policy
                                                        </Link>.
                                                    </p>
                                                </div>
                                            </Col>

                                            {/* Social Login Buttons - Side by Side */}
                                            <Col md={12} className="mb-3">
                                                <div className="mb-2 text-center">
                                                    <span className="fw-medium">Enroll Now and Start Learning with:</span>
                                                </div>
                                                <Row>
                                                    <Col xs={6} className="pe-1">
                                                        <Button
                                                            color="light"
                                                            block
                                                            className="d-flex align-items-center justify-content-center"
                                                            onClick={() => {
                                                                if (selectedCourses.length === 0) {
                                                                    setTemporaryAuthError("Please select at least one course first");
                                                                    return;
                                                                }
                                                                handleGoogleLogin();
                                                            }}
                                                            disabled={isGoogleLoading}
                                                            style={{ height: '38px' }}
                                                        >
                                                            {isGoogleLoading ? (
                                                                <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                                                            ) : (
                                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 48 48" className="me-1">
                                                                    <path fill="#EA4335" d="M24 9.5c3.54 0 6.71 1.22 9.21 3.6l6.85-6.85C35.9 2.38 30.47 0 24 0 14.62 0 6.51 5.38 2.56 13.22l7.98 6.19C12.43 13.72 17.74 9.5 24 9.5z"/>
                                                                    <path fill="#4285F4" d="M46.98 24.55c0-1.57-.15-3.09-.38-4.55H24v9.02h12.94c-.58 2.96-2.26 5.48-4.78 7.18l7.73 6c4.51-4.18 7.09-10.36 7.09-17.65z"/>
                                                                    <path fill="#FBBC05" d="M10.53 28.59c-.48-1.45-.76-2.99-.76-4.59s.27-3.14.76-4.59l-7.98-6.19C.92 16.46 0 20.12 0 24c0 3.88.92 7.54 2.56 10.78l7.97-6.19z"/>
                                                                    <path fill="#34A853" d="M24 48c6.48 0 11.93-2.13 15.89-5.81l-7.73-6c-2.15 1.45-4.92 2.3-8.16 2.3-6.26 0-11.57-4.22-13.47-9.91l-7.98 6.19C6.51 42.62 14.62 48 24 48z"/>
                                                                    <path fill="none" d="M0 0h48v48H0z"/>
                                                                </svg>
                                                            )}
                                                            <span className="d-none d-sm-inline">Google</span>
                                                        </Button>
                                                    </Col>
                                                    <Col xs={6} className="ps-1">
                                                        <Button
                                                            color="dark"
                                                            block
                                                            className="d-flex align-items-center justify-content-center"
                                                            onClick={() => {
                                                                if (selectedCourses.length === 0) {
                                                                    setTemporaryAuthError("Please select at least one course first");
                                                                    return;
                                                                }
                                                                handleGithubLogin();
                                                            }}
                                                            disabled={isGithubLoading}
                                                            style={{ height: '38px' }}
                                                        >
                                                            {isGithubLoading ? (
                                                                <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                                                            ) : (
                                                                <i className="mdi mdi-github me-1"></i>
                                                            )}
                                                            <span className="d-none d-sm-inline">GitHub</span>
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Col>

                                            {/* Auth Error Message */}
                                            {authError && (
                                                <Col md={12} className="mb-3">
                                                    <div className="alert alert-danger alert-dismissible fade show" role="alert">
                                                        {authError}
                                                        <button
                                                            type="button"
                                                            className="btn-close"
                                                            onClick={() => setAuthError(null)}
                                                            aria-label="Close"
                                                        ></button>
                                                    </div>
                                                </Col>
                                            )}
                                        </Row>
                                    </Form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <ModalVideo
                        channel="youtube"
                        isOpen={open}
                        videoId="yba7hPeTSjk"
                        onClose={() => setOpen(false)}
                    />
                </Container>
            </section>

            {/* Payment Modal */}
            {showPaymentModal && (
                <PaymentModal
                    isOpen={showPaymentModal}
                    toggle={togglePaymentModal}
                    userId={userFormData?.userId || "temp"}
                    name={userFormData?.name}
                    email={userFormData?.email}
                    currency="USD"
                    jwt={userFormData?.token || ""}
                    onPaystackSuccess={handlePaymentSuccess}
                    onFlutterwaveSuccess={handlePaymentSuccess}
                />
            )}

            {/* Success Toast Notification */}
            <div style={{
                position: 'fixed',
                top: '20px',
                right: '20px',
                zIndex: 9999,
                minWidth: '300px'
            }}>
                <Toast isOpen={showSuccessToast}>
                    <ToastHeader
                        toggle={() => setShowSuccessToast(false)}
                        icon="success"
                        className="text-white bg-success"
                    >
                        Success
                    </ToastHeader>
                    <ToastBody>
                        {toastMessage}
                    </ToastBody>
                </Toast>
            </div>
        </React.Fragment>
    );
};
export default Section;
