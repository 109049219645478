import { createSlice } from '@reduxjs/toolkit';

const selectedCoursesSlice = createSlice({
  name: 'selectedCourses',
  initialState: [],
  reducers: {
    setSelectedCourses: (state, action) => {
      return action.payload;
    },
    clearSelectedCourses: () => {
      return [];
    }
  }
});

export const { setSelectedCourses, clearSelectedCourses } = selectedCoursesSlice.actions;
export default selectedCoursesSlice.reducer;