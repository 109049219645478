import { configureStore } from '@reduxjs/toolkit';
import cartReducer from './Slices/cartSlice';
import authReducer from './Slices/authSlice';
import themeReducer from './Slices/themeSlice';
import selectedCoursesReducer from './Slices/selectedCoursesSlice';

export const store = configureStore({
  reducer: {
    cart: cartReducer,
    auth: authReducer,
    theme: themeReducer,
    selectedCourses: selectedCoursesReducer,
  },
});
