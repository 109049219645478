// React Basic and Bootstrap
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Row, Col, Container, CardBody, Card } from "reactstrap";
import { Helmet } from "react-helmet-async";
import CourseSchema from "../../../components/SEO/CourseSchema";

//Import Icons
import FeatherIcon from "feather-icons-react";

class PagePrivacy extends Component {
  componentDidMount() {
    document.body.classList = "";
    document.querySelector(".shoppingbtn")?.classList.add("btn-primary");
    window.addEventListener("scroll", this.scrollNavigation, true);
  }

  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    const navBar = document.getElementById("topnav");
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add("nav-sticky");
      } else {
        navBar.classList.remove("nav-sticky");
        document.querySelector(".shoppingbtn")?.classList.add("btn-primary");
        document.querySelector(".shoppingbtn")?.classList.remove("btn-light");
        document.querySelector(".settingbtn")?.classList.add("btn-soft-primary");
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        {/* SEO Metadata */}
        <Helmet>
          <title>Privacy Policy | CodesWithVic</title>
          <meta name="description" content="Privacy Policy for CodesWithVic - Learn how we collect, use, and protect your personal information. Our commitment to data privacy and security." />
          <meta name="keywords" content="privacy policy, data protection, personal information, privacy practices, CodesWithVic privacy" />
          <link rel="canonical" href="https://codeswithvic.com/privacy" />

          {/* Open Graph / Social Media */}
          <meta property="og:title" content="Privacy Policy | CodesWithVic" />
          <meta property="og:description" content="Privacy Policy for CodesWithVic - Learn how we collect, use, and protect your personal information." />
          <meta property="og:url" content="https://codeswithvic.com/privacy" />
          <meta property="og:type" content="website" />

          {/* Structured Data */}
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": "Privacy Policy",
              "description": "Privacy Policy for CodesWithVic - Learn how we collect, use, and protect your personal information.",
              "publisher": {
                "@type": "Organization",
                "name": "CodesWithVic",
                "logo": {
                  "@type": "ImageObject",
                  "url": "https://codeswithvic.com/logo.png"
                }
              },
              "license": "https://codeswithvic.com/privacy",
              "mainEntity": {
                "@type": "WebContent",
                "about": {
                  "@type": "Thing",
                  "name": "Privacy Policy"
                },
                "datePublished": "2022-01-01",
                "dateModified": "2023-05-01"
              }
            })}
          </script>
        </Helmet>

        {/* Include Course Schema */}
        <CourseSchema />

        {/* breadcrumb */}
        <section className="bg-half-170 bg-light d-table w-100 d-print-none">
            <Container>
                <Row className="mt-5 justify-content-center">
                    <Col lg={12} className="text-center">
                        <div className="pages-heading">
                            <h1 className="title">Privacy Policy</h1>
                            <ul className="list-unstyled mt-4 mb-0">
                                <li className="list-inline-item h6 date dark-text"> <span className="text-dark dark-text">Last Revised:</span> January 1, 2022</li>
                            </ul>
                        </div>
                    </Col>
                </Row>

                <div className="position-breadcrumb">
                    <nav aria-label="breadcrumb" className="d-inline-block">
                        <ul className="breadcrumb bg-white rounded shadow mb-0 px-4 py-2">
                            <li className="breadcrumb-item"><Link to="/" className="dark-text">CodesWithVic</Link></li>
                            <li className="breadcrumb-item"><Link to="#" className="dark-text">Pages</Link></li>
                            <li className="breadcrumb-item active dark-text" aria-current="page">Privacy Policy</li>
                        </ul>
                    </nav>
                </div>
            </Container>
        </section>

        <div className="position-relative">
          <div className="shape overflow-hidden text-white">
            <svg
              viewBox="0 0 2880 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
                fill="currentColor"
              ></path>
            </svg>
          </div>
        </div>

        <section className="section">
          <Container>
            <Row className="justify-content-center">
              <Col lg={9}>
                <Card className="card shadow rounded border-0">
                  <CardBody>

<section className="mb-4">
  <h3>1. Introduction</h3>
  <p className="text-dark dark-text">Welcome to CodesWithVic ("Platform", "we", "us", "our"). We are committed to protecting your privacy and ensuring that your personal information is handled in a safe and responsible manner. This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our Platform. By using our Platform, you agree to the collection and use of information in accordance with this policy.</p>
</section>

<section className="mb-4">
  <h3>2. Information We Collect</h3>
  <ul className="text-dark dark-text">
    <li><strong>Personal Information:</strong> We may collect personal information that you provide to us directly, such as your name, email address, and contact details when you register for an account, subscribe to our newsletter, or contact us for support.</li>
    <li><strong>Usage Data:</strong> We collect information about how you use our Platform, including your IP address, browser type, operating system, referring URLs, and pages viewed.</li>
    <li><strong>Cookies and Tracking Technologies:</strong> We use cookies and similar tracking technologies to enhance your experience on our Platform, analyze usage patterns, and serve targeted advertisements. You can manage your cookie preferences through your browser settings.</li>
  </ul>
</section>

<section className="mb-4">
  <h3>3. How We Use Your Information</h3>
  <ul className="text-dark dark-text">
    <li><strong>To Provide and Improve Our Services:</strong> We use your information to deliver, maintain, and improve our services, and to personalize your experience on our Platform.</li>
    <li><strong>To Communicate with You:</strong> We may use your contact information to send you updates, newsletters, and promotional materials. You can opt-out of receiving such communications at any time.</li>
    <li><strong>To Analyze Usage and Trends:</strong> We analyze usage data to understand how users interact with our Platform and to make improvements.</li>
    <li><strong>To Comply with Legal Obligations:</strong> We may use your information to comply with legal obligations or to respond to legal requests and processes.</li>
  </ul>
</section>

<section className="mb-4">
  <h3>4. How We Share Your Information</h3>
  <ul className="text-dark dark-text">
    <li><strong>Service Providers:</strong> We may share your information with third-party service providers who perform services on our behalf, such as payment processing, data analysis, and email delivery.</li>
    <li><strong>Legal Requirements:</strong> We may disclose your information if required to do so by law or in response to valid requests by public authorities.</li>
    <li><strong>Business Transfers:</strong> In the event of a merger, acquisition, or sale of assets, we may transfer your information as part of the transaction.</li>
  </ul>
</section>

<section className="mb-4">
  <h3>5. Your Rights and Choices</h3>
  <ul className="text-dark dark-text">
    <li><strong>Access and Update:</strong> You have the right to access and update your personal information. You can do so by logging into your account or contacting us directly.</li>
    <li><strong>Opt-Out:</strong> You can opt-out of receiving marketing communications from us by following the unsubscribe instructions in those communications.</li>
    <li><strong>Cookies:</strong> You can manage your cookie preferences through your browser settings. However, disabling cookies may affect your experience on our Platform.</li>
  </ul>
</section>

<section className="mb-4">
  <h3>6. Security</h3>
  <p className="text-dark dark-text">We take reasonable measures to protect your information from unauthorized access, use, or disclosure. However, no method of transmission over the internet or electronic storage is completely secure, so we cannot guarantee absolute security.</p>
</section>

<section className="mb-4">
  <h3>7. Links to Other Websites</h3>
  <p className="text-dark dark-text">Our Platform may contain links to other websites that are not operated by us. We are not responsible for the privacy practices or content of such third-party websites. We encourage you to review the privacy policies of any third-party sites you visit.</p>
</section>

<section className="mb-4">
  <h3>8. Changes to This Privacy Policy</h3>
  <p className="text-dark dark-text">We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on our Platform. You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
</section>

<section className="mb-4">
  <h3>9. Contact Us</h3>
  <p className="text-dark dark-text">If you have any questions or concerns about this Privacy Policy, please contact us at:</p>
  <ul className="text-dark dark-text">
    <li><strong>CodesWithVic</strong></li>
    <li><strong>Address:</strong> Abuja, Nigeria & Toronto, Canada</li>
    <li><strong>Email Address:</strong> hello@codeswithvic.com</li>
  </ul>
</section>
                    {/* <h5 className="card-title">Overview :</h5>
                    <p className="text-muted">
                      It seems that only fragments of the original text remain
                      in the Lorem Ipsum texts used today. One may speculate
                      that over the course of time certain letters were added or
                      deleted at various positions within the text.
                    </p>
                    <p className="text-muted">
                      In the 1960s, the text suddenly became known beyond the
                      professional circle of typesetters and layout designers
                      when it was used for Letraset sheets (adhesive letters on
                      transparent film, popular until the 1980s) Versions of the
                      text were subsequently included in DTP programmes such as
                      PageMaker etc.
                    </p>
                    <p className="text-muted">
                      There is now an abundance of readable dummy texts. These
                      are usually used when a text is required purely to fill a
                      space. These alternatives to the classic Lorem Ipsum texts
                      are often amusing and tell short, funny or nonsensical
                      stories.
                    </p>

                    <h5 className="card-title">We use your information to :</h5>
                    <ul className="list-unstyled feature-list text-muted">
                      <li>
                        <i>
                          <FeatherIcon
                            icon="arrow-right"
                            className="fea icon-sm me-2"
                          />
                        </i>
                        Digital Marketing Solutions for Tomorrow
                      </li>
                      <li>
                        <i>
                          <FeatherIcon
                            icon="arrow-right"
                            className="fea icon-sm me-2"
                          />
                        </i>
                        Our Talented & Experienced Marketing Agency
                      </li>
                      <li>
                        <i>
                          <FeatherIcon
                            icon="arrow-right"
                            className="fea icon-sm me-2"
                          />
                        </i>
                        Create your own skin to match your brand
                      </li>
                      <li>
                        <i>
                          <FeatherIcon
                            icon="arrow-right"
                            className="fea icon-sm me-2"
                          />
                        </i>
                        Digital Marketing Solutions for Tomorrow
                      </li>
                      <li>
                        <i>
                          <FeatherIcon
                            icon="arrow-right"
                            className="fea icon-sm me-2"
                          />
                        </i>
                        Our Talented & Experienced Marketing Agency
                      </li>
                      <li>
                        <i>
                          <FeatherIcon
                            icon="arrow-right"
                            className="fea icon-sm me-2"
                          />
                        </i>
                        Create your own skin to match your brand
                      </li>
                    </ul>
                    <h5 className="card-title">
                      Information Provided Voluntarily :
                    </h5>
                    <p className="text-muted">
                      In the 1960s, the text suddenly became known beyond the
                      professional circle of typesetters and layout designers
                      when it was used for Letraset sheets (adhesive letters on
                      transparent film, popular until the 1980s) Versions of the
                      text were subsequently included in DTP programmes such as
                      PageMaker etc.
                    </p>
                    <Link to="#" className="btn btn-soft-primary d-print-none">
                      Print
                    </Link> */}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
export default PagePrivacy;
