import {
    PaymentElement,
    LinkAuthenticationElement
  } from '@stripe/react-stripe-js'
  import React, {useState} from 'react'
  import {useStripe, useElements} from '@stripe/react-stripe-js';
  
  export default function CheckoutForm({email,subscribe, onClose}) {
    const stripe = useStripe();
    const elements = useElements();
    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
  
    const handleSubmit = async (e) => {
      e.preventDefault();
  
      if (!stripe || !elements) {
        return;
      }
  
      setIsLoading(true);
  
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          //return_url: `${window.location.origin}/completion`,
        },
        redirect: 'if_required',

      });
  
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
        if (error) {
            if (error.type === "card_error" || error.type === "validation_error") {
              setMessage(error.message);
            } else {
              setMessage("An unexpected error occured.");
            }
        }else if(paymentIntent && paymentIntent.status === "succeeded"){
            setMessage("Success! Your test payment has been processed. You will be redirected to the completion page.");

            const reference = {
                amount: paymentIntent.amount,
                currency: paymentIntent.currency,
                transaction_id: paymentIntent.id,
                flw_ref: paymentIntent.id,
                tx_ref: paymentIntent.id,
                status: paymentIntent.status
              };
              subscribe(reference, 'Stripe');
            onClose();
        }
  
      setIsLoading(false);
    }
  
    return (
      <form id="payment-form" onSubmit={handleSubmit}>
        <LinkAuthenticationElement id="link-authentication-element"
          // Access the email value like so:
          // onChange={(event) => {
          //  setEmail(event.value.email);
          // }}
          //
          // Prefill the email field like so:
          options={{defaultValues: {email: email}}}
          />
        <PaymentElement id="payment-element" />
        <button disabled={isLoading || !stripe || !elements} id="submit" className="btn btn-primary mt-2">
          <span id="button-text">
            {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
          </span>
        </button>
        {/* Show any error or success messages */}
        {message && <div id="payment-message">{message}</div>}
      </form>
    )
  }