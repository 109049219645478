import { createSlice } from '@reduxjs/toolkit';
import { setSelectedCourses } from './selectedCoursesSlice';
import { currencySymbol } from '../../util/helper';

const initialState = {
  items: [],
  totalQuantity: 0,
  totalPrice: 0,
  showModal: false,
  courseIds: [],
};

export const cartSlice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    addToCart: (state, action) => {
      const newItem = action.payload;
      const existingItem = state.items.find(item => item.id === newItem.id);

      if (!existingItem) {
        state.items.push({
          ...newItem,
          quantity: 1,
        });
        state.totalQuantity += 1;
        state.totalPrice += Number(newItem.price);
        state.courseIds.push(newItem.id);
      }
    },
    removeItem: (state, action) => {
      const id = action.payload.id;
      state.items = state.items.filter(item => item.id !== id);
      state.totalQuantity = state.items.reduce((total, item) => total + item.quantity, 0);
      state.totalPrice = state.items.reduce((total, item) => total + (item.isOnDiscount ? item.discountedPrice : item.price) * item.quantity, 0);
      state.courseIds = state.courseIds.filter(id => id !== action.payload.id);
    },
    clearCart: (state) => {
      state.items = [];
      state.totalQuantity = 0;
      state.totalPrice = 0;
      state.courseIds = [];
    },
    calculateTotals: (state) => {
      let totalQuantity = 0;
      let totalPrice = 0;

      state.items.forEach(item => {
        totalQuantity += item.quantity;
        totalPrice += item.isOnDiscount ? item.discountedPrice : item.price * item.quantity;
      });

      state.totalQuantity = totalQuantity;
      state.totalPrice = totalPrice;
    },
    setShowModal: (state, action) => {
      state.showModal = action.payload;
    },
    setCourseIds: (state, action) => {
      state.courseIds = action.payload;
    },
  },
});

// Export the actions
export const { removeItem, clearCart, calculateTotals, setShowModal, setCourseIds } = cartSlice.actions;

// Export the reducer
export default cartSlice.reducer;

// Add a new action to sync selected courses
export const syncSelectedCourses = createSlice({
  name: 'syncSelectedCourses',
  initialState: [],
  reducers: {
    setSelectedCourses: (state, action) => {
      return action.payload;
    },
  },
}).actions.setSelectedCourses;

// Export the addItem thunk action
export const addItem = (item) => (dispatch, getState) => {
  // Original functionality
  dispatch(cartSlice.actions.addToCart(item));

  // New functionality - update selected courses
  setTimeout(() => {
    const state = getState();
    const currentItems = state.cart.items;
    const courseOptions = currentItems.map(course => ({
      value: course,
      label: `${course.name} - ${currencySymbol(course.currency)}${course.isOnDiscount ? course.discountedPrice : course.price}`
    }));

    dispatch(setSelectedCourses(courseOptions));
  }, 0);
};
