import React from 'react'
import { Col, Container, Row } from 'reactstrap'

const Features = () => {
        return (
            <React.Fragment>
                <section className="py-2 bg-light">
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={3} md={6} className="col-12">
                                <div className="d-flex features feature-primary pt-4 pb-4">
                                    <div className="icon text-center rounded-circle text-primary me-3 h5 mb-0">
                                        <i className="uil uil-analytics text-primary"></i>
                                    </div>
                                    <div className="flex-1">
                                        <h4 className="title">No Experience Needed</h4>
                                        <p className="text-muted para mb-0">Start from scratch.</p>
                                    </div>
                                </div>
                            </Col>

                            <Col lg={3} md={6} className="col-12">
                                <div className="d-flex features feature-primary pt-4 pb-4">
                                    <div className="icon text-center rounded-circle text-primary me-3 h5 mb-0">
                                        <i className="uil uil-airplay text-primary"></i>
                                    </div>
                                    <div className="flex-1">
                                        <h4 className="title">Learn at your own pace</h4>
                                        <p className="text-muted para mb-0">12+ weeks, offline or online.</p>
                                    </div>
                                </div>
                            </Col>

                            <Col lg={3} md={6} className="col-12">
                                <div className="d-flex features feature-primary pt-4 pb-4">
                                    <div className="icon text-center rounded-circle text-primary me-3 h5 mb-0">
                                        <i className="uil uil-clapper-board text-primary"></i>
                                    </div>
                                    <div className="flex-1">
                                        <h4 className="title">Code Anywhere</h4>
                                        <p className="text-muted para mb-0">Laptop + VS Code.</p>
                                    </div>
                                </div>
                            </Col>

                            <Col lg={3} md={6} className="col-12">
                                <div className="d-flex features feature-primary pt-4 pb-4">
                                    <div className="icon text-center rounded-circle text-primary me-3 h5 mb-0">
                                        <i className="uil uil-users-alt text-primary"></i>
                                    </div>
                                    <div className="flex-1">
                                        <h4 className="title">24/7 Support</h4>
                                        <p className="text-muted para mb-0">Get help when you need it.</p>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
        );
};
export default Features;
