import React from "react";
import { Navigate } from "react-router-dom";
import Course from "../pages/Course/index";
// import LoginCallback from "../pages/Auth/LoginCallback";
import Landing from "../pages/Auth/Landing";

//Utility
import PagePrivacy from "../pages/Pages/Utility/PagePrivacy";
import PageTerms from "../pages/Pages/Utility/PageTerms";
//Auth Pages


const routes = [
  //Index Main
  {
    path: "/",
    exact: true,
    component: <Navigate to="/index" />,
  },
  { path: "/index", component: <Course />, isTopbarDark: true },

    //Utility
    { path: "/terms", component: <PageTerms />, isTopbarDark: true },
    { path: "/privacy", component: <PagePrivacy />, isTopbarDark: true },

  //Auth
  // { path: "/login", component: <LoginCallback />, isTopbarDark: true },
  { path: "/landing", component: <Landing />, isTopbarDark: true },
];

export default routes;
