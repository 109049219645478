import React, { useState, useEffect } from 'react';
import { FlutterWaveButton, closePaymentModal } from 'flutterwave-react-v3';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { clearCart, calculateTotals } from '../Redux/Slices/cartSlice';
import { PaystackButton } from 'react-paystack';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm';
import paystackLogo from '../assets/images/paystack.svg';
import flutterwaveLogo from '../assets/images/flutterwave.svg';
import stripeLogo from '../assets/images/stripe.svg';
import { getCachedContent } from '../util/helper';

const PaymentModal = ({ userId, name, email, currency, jwt, isOpen, toggle, onPaystackSuccess, onFlutterwaveSuccess }) => {
  const dispatch = useDispatch();
  const { items, courseIds } = useSelector((state) => state.cart);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const baseUrlInternal = process.env.REACT_APP_BASE_URL_INTERNAL;
  const cart = useSelector((state) => state.cart);
  const [totalAmount, setTotalAmount] = useState(0);
  const [stripePromise, setStripePromise] = useState(null);
  const [clientSecret, setClientSecret] = useState('');
  const [showStripeForm, setShowStripeForm] = useState(false);

  useEffect(() => {
    setTotalAmount(cart.totalPrice);
    fetch(`${baseUrl}/courses/config`).then(async (r) => {
      const { publishableKey } = await r.json();
      setStripePromise(loadStripe(publishableKey));
    });
  }, [items, dispatch, baseUrl]);

  const handleStripeClick = () => {
    // Create PaymentIntent when Stripe button is clicked
    fetch(`${baseUrl}/courses/create-payment-intent`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        courseIds: courseIds,
        userId: userId,
        location: getCachedContent('country').trim(),
        email: email,
        name: name
      }),
    })
      .then((res) => res.json())
      .then(({ clientSecret }) => {
        setClientSecret(clientSecret);
        setShowStripeForm(true);
      });
  };

  const closeStripeForm = () => {
    setShowStripeForm(false);
  };

  const componentProps = {
    publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
    email: email,
    amount: currency === 'NGN' ? totalAmount + 100 : totalAmount, // Paystack expects amount in kobo
    currency: currency,
    text: 'Pay with Paystack',
    metadata: {
      name: name,
      phone: '',
    },
    onSuccess: (reference) => {
      let ref = {
        amount: totalAmount,
        currency: 'NGN',
        transaction_id: reference.transaction,
        status: reference.status,
        flw_ref: reference.trans,
        tx_ref: reference.reference
      };
      onPaystackSuccess(reference);
      subscribe(ref, 'Paystack');
      toggle(); // Close modal after successful payment
    },
    onClose: () => {
      // Don't close the parent modal when Paystack modal is closed
      console.log("Paystack modal closed");
      // No toggle() call here
    },
  };

  const flutterwaveConfig = {
    public_key: process.env.REACT_APP_FLUTTERWAVE_PUBLIC_KEY,
    tx_ref: Date.now(),
    amount: currency === 'NGN' ? totalAmount + 100 : totalAmount,
    currency: currency,
    payment_options: 'card,banktransfer',
    customer: {
      email: email,
    },
    customizations: {
      title: 'Payment for services',
      description: 'Payment for your order',
    },
  };

  const handleFlutterwavePayment = (response) => {
    onFlutterwaveSuccess(response);
    subscribe(response, 'Flutterwave');
    closePaymentModal(); // Closes the Flutterwave modal programmatically
    toggle(); // Close our custom modal after successful payment
  };

  const handleFlutterwaveClose = () => {
    // Don't close the parent modal when Flutterwave modal is closed
    console.log("Flutterwave modal closed");
    // No toggle() call here
  };

  const subscribe = async (reference, paymentChannel) => {
    try {
      let csIds = [];
      csIds.push(...courseIds); // Store the course ID in an array
      const response = await axios.post(
        `${baseUrl}/subscriptions`,
        {
          userId: userId,
          courseIds: csIds, // Use the array of course IDs
          amountPaid: reference.amount,
          paymentTransactionID: reference.transaction_id,
          paymentCurrency: reference.currency,
          flw_ref: reference.flw_ref,
          tx_ref: reference.tx_ref,
          paymentStatus: reference.status,
          paymentChannel, // Indicate which payment channel was used
        },
        {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }
      );

      if (response.status === 201) {
        alert('Subscription successful');
        dispatch(clearCart());
        external(jwt);
      } else if (response.status === 202) {
        alert('Subscription pending');
      } else {
        console.error('Unexpected response status:', response.status);
      }
    } catch (error) {
      console.error('Subscription failed:', error);
    }
  };

  const external = async (jwt) => {
    window.location.href = `${baseUrlInternal}?jwt=${jwt}`;
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader className="position-relative">
        <span>{showStripeForm ? 'Complete Your Payment' : 'Payment Options'}</span>
        <button
          type="button"
          className="btn-close position-absolute"
          onClick={() => {
            if (showStripeForm) {
              setShowStripeForm(false);
            } else {
              toggle();
            }
          }}
          style={{
            top: '15px',
            right: '15px',
            fontSize: '0.9rem'
          }}
          aria-label="Close"
        >X</button>
      </ModalHeader>
      <ModalBody className="modal-content pb-4">
        {showStripeForm ? (
          // Show Stripe payment form
          clientSecret && stripePromise && (
            <Elements stripe={stripePromise} options={{ clientSecret }}>
              <CheckoutForm email={email} subscribe={subscribe} onClose={closeStripeForm} />
            </Elements>
          )
        ) : (
          // Show payment options
          <>
            {/* Stripe Button */}
            <button
              className='btn d-flex align-items-center justify-content-center'
              onClick={handleStripeClick}
              style={{
                backgroundColor: '#00A6E2',
                color: 'white',
                borderRadius: '5px',
                border: 'none',
                padding: '12px',
                marginBottom: '10px',
                width: '100%',
                height: '60px',
                transition: 'background-color 0.3s'
              }}
              onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#1CB5ED'}
              onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#00A6E2'}
              onMouseDown={(e) => e.currentTarget.style.backgroundColor = '#0095CB'}
              onMouseUp={(e) => e.currentTarget.style.backgroundColor = '#1CB5ED'}
              aria-label="Pay with Stripe"
            >
              <img
                src={stripeLogo}
                alt="Stripe"
                style={{ height: '36px', width: 'auto', filter: 'brightness(0) invert(1)' }}
              />
            </button>

            {/* Hidden original Paystack button */}
            <div style={{ display: 'none' }}>
              <PaystackButton className="paystack-button" {...componentProps} />
            </div>

            {/* Custom Paystack Button */}
            <button
              type="button"
              onClick={() => document.querySelector('.paystack-button').click()}
              style={{
                backgroundColor: '#00A65E',
                color: 'white',
                borderRadius: '5px',
                border: 'none',
                padding: '12px',
                marginBottom: '10px',
                width: '100%',
                height: '60px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                transition: 'background-color 0.3s'
              }}
              onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#00B96A'}
              onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#00A65E'}
              onMouseDown={(e) => e.currentTarget.style.backgroundColor = '#009554'}
              onMouseUp={(e) => e.currentTarget.style.backgroundColor = '#00B96A'}
              aria-label="Pay with Paystack"
            >
              {/* Use text instead of SVG for better visibility */}
              <span style={{
                color: 'white',
                fontWeight: 'bold',
                fontSize: '24px',
                fontFamily: 'Arial, sans-serif'
              }}>
                paystack
              </span>
            </button>

            {/* Hidden original Flutterwave button */}
            {flutterwaveConfig && flutterwaveConfig.public_key && (
              <>
                <div style={{ display: 'none' }}>
                  <FlutterWaveButton
                    className="flutterwave-button"
                    {...flutterwaveConfig}
                    callback={handleFlutterwavePayment}
                    onClose={handleFlutterwaveClose} // Use our custom handler
                    text="Pay with Flutterwave"
                  />
                </div>

                {/* Custom Flutterwave Button */}
                <button
                  type="button"
                  onClick={() => document.querySelector('.flutterwave-button').click()}
                  style={{
                    backgroundColor: '#673AB7',
                    color: 'white',
                    borderRadius: '5px',
                    border: 'none',
                    padding: '12px',
                    width: '100%',
                    height: '60px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    transition: 'background-color 0.3s'
                  }}
                  onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#7E57C2'}
                  onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#673AB7'}
                  onMouseDown={(e) => e.currentTarget.style.backgroundColor = '#5E35B1'}
                  onMouseUp={(e) => e.currentTarget.style.backgroundColor = '#7E57C2'}
                  aria-label="Pay with Flutterwave"
                >
                  <img
                    src={flutterwaveLogo}
                    alt="Flutterwave"
                    style={{ height: '36px', width: 'auto', filter: 'brightness(0) invert(1)' }}
                  />
                </button>
              </>
            )}
          </>
        )}
      </ModalBody>
    </Modal>
  );
};

export default PaymentModal;