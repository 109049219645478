// React Basic and Bootstrap
import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  Collapse,
} from "reactstrap";
import { Helmet } from "react-helmet-async";
import CourseSchema from "../../../components/SEO/CourseSchema";

//Import Icons
import FeatherIcon from "feather-icons-react";

class PageTerms extends Component {
  constructor(props) {
    super(props);
    this.state = {

      col1: true,
      col2: false,
      col3: false,
      col4: false,
      col5: false,
    };
    this.t_col1 = this.t_col1.bind(this);
    this.t_col2 = this.t_col2.bind(this);
    this.t_col3 = this.t_col3.bind(this);
    this.t_col4 = this.t_col4.bind(this);
    this.t_col5 = this.t_col5.bind(this);
  }

  t_col1() {
    this.setState({
      col1: !this.state.col1,
      col2: false,
      col3: false,
      col4: false,
      col5: false,
    });
  }
  t_col2() {
    this.setState({
      col2: !this.state.col2,
      col1: false,
      col3: false,
      col4: false,
      col5: false,
    });
  }
  t_col3() {
    this.setState({
      col3: !this.state.col3,
      col2: false,
      col1: false,
      col4: false,
      col5: false,
    });
  }
  t_col4() {
    this.setState({
      col4: !this.state.col4,
      col2: false,
      col3: false,
      col1: false,
      col5: false,
    });
  }
  t_col5() {
    this.setState({
      col5: !this.state.col5,
      col2: false,
      col3: false,
      col1: false,
      col4: false,
    });
  }

  componentDidMount() {
    document.body.classList = "";
    document.querySelector(".shoppingbtn")?.classList.add("btn-primary");
    window.addEventListener("scroll", this.scrollNavigation, true);
  }

  // Make sure to remove the DOM listener when the component is unmounted.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var doc = document.documentElement;
    const navBar = document.getElementById("topnav");
    var top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0);
    if (navBar != null) {
      if (top > 80) {
        navBar.classList.add("nav-sticky");
      } else {
        navBar.classList.remove("nav-sticky");
        document.querySelector(".shoppingbtn")?.classList.add("btn-primary");
        document.querySelector(".shoppingbtn")?.classList.remove("btn-light");
        document.querySelector(".settingbtn")?.classList.add("btn-soft-primary");
      }
    }
  };

  render() {
    return (
      <React.Fragment>
        {/* SEO Metadata */}
        <Helmet>
          <title>Terms & Conditions | CodesWithVic</title>
          <meta name="description" content="Terms and Conditions for CodesWithVic - Learn programming directly in VS Code with interactive courses. Read our terms of service, user agreements, and legal policies." />
          <meta name="keywords" content="terms and conditions, legal, terms of service, user agreement, CodesWithVic terms, programming courses terms" />
          <link rel="canonical" href="https://codeswithvic.com/terms" />

          {/* Open Graph / Social Media */}
          <meta property="og:title" content="Terms & Conditions | CodesWithVic" />
          <meta property="og:description" content="Terms and Conditions for CodesWithVic - Learn programming directly in VS Code with interactive courses." />
          <meta property="og:url" content="https://codeswithvic.com/terms" />
          <meta property="og:type" content="website" />

          {/* Structured Data */}
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "https://schema.org",
              "@type": "WebPage",
              "name": "Terms & Conditions",
              "description": "Terms and Conditions for CodesWithVic - Learn programming directly in VS Code with interactive courses.",
              "publisher": {
                "@type": "Organization",
                "name": "CodesWithVic",
                "logo": {
                  "@type": "ImageObject",
                  "url": "https://codeswithvic.com/logo.png"
                }
              },
              "license": "https://codeswithvic.com/terms",
              "mainEntity": {
                "@type": "WebContent",
                "about": {
                  "@type": "Thing",
                  "name": "Terms and Conditions"
                },
                "datePublished": "2022-01-01",
                "dateModified": "2023-05-01"
              }
            })}
          </script>
        </Helmet>

        {/* Include Course Schema */}
        <CourseSchema />

        {/* breadcrumb */}
        <section className="bg-half-170 bg-light d-table w-100">
          <Container>
            <Row className="mt-5 justify-content-center">
              <Col lg={12} className="text-center">
                <div className="pages-heading">
                  <h1 className="mb-0">Terms & Conditions</h1>
                  <p className="para-desc mx-auto dark-text">Effective Date: January 1, 2022</p>
                </div>
              </Col>
            </Row>

            <div className="position-breadcrumb">
              <nav aria-label="breadcrumb" className="d-inline-block">
                <ul className="breadcrumb bg-white rounded shadow mb-0 px-4 py-2">
                  <li className="breadcrumb-item">
                    <Link to="/" className="dark-text">CodesWithVic</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="#" className="dark-text">Pages</Link>
                  </li>
                  <li className="breadcrumb-item active dark-text" aria-current="page">
                    Terms & Conditions
                  </li>
                </ul>
              </nav>
            </div>
          </Container>
        </section>

        <section className="section">
          <Container>
            <Row className="justify-content-center">
              <Col lg={9}>
                <Card className="shadow border-0 rounded">
                  <CardBody>
                    <h5 className="card-title">Introduction :</h5>
                    <p className="text-dark dark-text">
                      Welcome to CodesWithVic ("Platform", "we", "us", "our"). These Terms and Conditions ("Terms") govern your use of our website, services, and content provided by CodesWithVic ("Company"). By accessing or using our Platform, you agree to be bound by these Terms. If you do not agree with any part of these Terms, please do not use our Platform.
                    </p>

                    <h5 className="card-title mt-4">Use of the Platform :</h5>
                    <ul className="list-unstyled text-dark dark-text">
                      <li className="mt-2">
                        <FeatherIcon
                          icon="arrow-right"
                          className="fea icon-sm me-2"
                        />
                        <strong>Eligibility:</strong> You must be at least 18 years old to use our Platform. By using our Platform, you represent and warrant that you meet this age requirement.
                      </li>
                      <li className="mt-2">
                        <FeatherIcon
                          icon="arrow-right"
                          className="fea icon-sm me-2"
                        />
                        <strong>Account Registration:</strong> To access certain features, you may be required to register for an account. You agree to provide accurate and complete information during registration and to update such information to keep it accurate and complete.
                      </li>
                      <li className="mt-2">
                        <FeatherIcon
                          icon="arrow-right"
                          className="fea icon-sm me-2"
                        />
                        <strong>Account Security:</strong> You are responsible for maintaining the confidentiality of your account login information and for all activities that occur under your account. Notify us immediately of any unauthorized use of your account or any other breach of security.
                      </li>
                    </ul>

                    <h5 className="card-title mt-4">Content :</h5>
                    <ul className="list-unstyled text-dark dark-text">
                      <li className="mt-2">
                        <FeatherIcon
                          icon="arrow-right"
                          className="fea icon-sm me-2"
                        />
                        <strong>Ownership:</strong> All content provided on our Platform, including but not limited to text, graphics, logos, images, and videos, is the property of CodesWithVic or its content suppliers and is protected by intellectual property laws.
                      </li>
                      <li className="mt-2">
                        <FeatherIcon
                          icon="arrow-right"
                          className="fea icon-sm me-2"
                        />
                        <strong>User Content:</strong> You retain ownership of any content you submit to our Platform. By submitting content, you grant us a worldwide, royalty-free, perpetual, and non-exclusive license to use, reproduce, modify, and distribute such content in connection with our Platform.
                      </li>
                    </ul>

                    <h5 className="card-title mt-4">Prohibited Conduct :</h5>
                    <ul className="list-unstyled text-dark dark-text">
                      <li className="mt-2">
                          <FeatherIcon
                            icon="arrow-right"
                            className="fea icon-sm me-2"
                          />
                        You agree not to use the Platform for any illegal or unauthorized purpose.
                      </li>
                      <li className="mt-2">
                          <FeatherIcon
                            icon="arrow-right"
                            className="fea icon-sm me-2"
                          />
                        You agree not to interfere with or disrupt the Platform or servers.
                      </li>
                      <li className="mt-2">
                          <FeatherIcon
                            icon="arrow-right"
                            className="fea icon-sm me-2"
                          />
                        You agree not to transmit any malicious software, viruses, or harmful code.
                      </li>
                      <li className="mt-2">
                          <FeatherIcon
                            icon="arrow-right"
                            className="fea icon-sm me-2"
                          />
                        You agree not to attempt to gain unauthorized access to any part of the Platform.
                      </li>
                    </ul>

                    <h5 className="card-title mt-4">Paid Services :</h5>
                    <ul className="list-unstyled text-dark dark-text">
                      <li className="mt-2">
                          <FeatherIcon
                            icon="arrow-right"
                            className="fea icon-sm me-2"
                          />
                        <strong>Fees:</strong> Some features of the Platform may require payment. All fees are described on our Platform and are subject to change.
                      </li>
                      <li className="mt-2">
                          <FeatherIcon
                            icon="arrow-right"
                            className="fea icon-sm me-2"
                          />
                        <strong>Refunds:</strong> Refunds for paid services will be provided in accordance with our refund policy.
                      </li>
                    </ul>

                    <h5 className="card-title mt-4">Termination :</h5>
                    <p className="text-dark dark-text">
                      We reserve the right to terminate or suspend your account and access to the Platform if you violate these Terms or for any other reason at our sole discretion.
                    </p>

                    <h5 className="card-title mt-4">Disclaimer of Warranties :</h5>
                    <p className="text-dark dark-text">
                      The Platform is provided "as is" and "as available" without any warranties of any kind, whether express or implied. We do not warrant that the Platform will be uninterrupted, secure, or error-free.
                    </p>

                    <h5 className="card-title mt-4">Limitation of Liability :</h5>
                    <p className="text-dark dark-text">
                      To the fullest extent permitted by law, CodesWithVic shall not be liable for any indirect, incidental, special, or consequential damages arising from your use of the Platform.
                    </p>

                    <h5 className="card-title mt-4">Governing Law :</h5>
                    <p className="text-dark dark-text">
                      These Terms are governed by and construed in accordance with the laws of Nigeria or Canada. Any disputes arising from these Terms or your use of the Platform shall be resolved in the courts located in Nigeria or Canada.
                    </p>

                    <h5 className="card-title mt-4">Changes to These Terms :</h5>
                    <p className="text-dark dark-text">
                      We reserve the right to modify these Terms at any time. Any changes will be posted on our Platform, and your continued use of the Platform constitutes your acceptance of the revised Terms.
                    </p>

                    <h5 className="card-title mt-4">Contact Us :</h5>
                    <p className="text-dark dark-text">
                      If you have any questions or concerns about these Terms, please contact us at:
                    </p>
                    <ul className="list-unstyled text-dark dark-text">
                      <li className="mt-2">
                        <FeatherIcon
                          icon="arrow-right"
                          className="fea icon-sm me-2"
                        />
                        <strong>CodesWithVic</strong>
                      </li>
                      <li className="mt-2">
                        <FeatherIcon
                          icon="arrow-right"
                          className="fea icon-sm me-2"
                        />
                        <strong>Address:</strong> Abuja, Nigeria & Toronto, Canada
                      </li>
                      <li className="mt-2">
                        <FeatherIcon
                          icon="arrow-right"
                          className="fea icon-sm me-2"
                        />
                        <strong>Email Address:</strong> hello@codeswithvic.com
                      </li>
                    </ul>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}
export default PageTerms;
