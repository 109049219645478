import React, { useEffect } from 'react';

const GithubCallback = () => {
    useEffect(() => {
        // Get the token from the URL
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        const state = urlParams.get('state');

        if (token && state) {
            // Store the token in localStorage for the main window to access
            localStorage.setItem('github_oauth_token', JSON.stringify({ token, state }));

            // Close this window
            window.close();
        } else {
            // Handle error case
            document.body.innerHTML = '<h3>Authentication failed. You can close this window.</h3>';
        }
    }, []);

    return (
        <div className="text-center p-5">
            <h2>GitHub Authentication</h2>
            <p>Processing your login... This window should close automatically.</p>
        </div>
    );
};

export default GithubCallback;