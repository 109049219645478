import React from 'react';
import { Helmet } from 'react-helmet-async';

const DefaultSEO = () => {
  return (
    <Helmet>
      {/* Primary Meta Tags */}
      <title>CodesWithVic - Learn Programming Directly in VS Code</title>
      <meta name="title" content="CodesWithVic - Learn Programming Directly in VS Code" />
      <meta name="description" content="Interactive programming courses that run directly in VS Code. Learn coding with hands-on practice in a real development environment." />
      <meta name="keywords" content="programming courses, coding tutorials, VS Code learning, interactive coding, web development, programming education" />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content="https://codeswithvic.com/" />
      <meta property="og:title" content="CodesWithVic - Learn Programming Directly in VS Code" />
      <meta property="og:description" content="Interactive programming courses that run directly in VS Code. Learn coding with hands-on practice in a real development environment." />
      <meta property="og:image" content="https://codeswithvic.com/images/og-image.jpg" />

      {/* Twitter */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content="https://codeswithvic.com/" />
      <meta property="twitter:title" content="CodesWithVic - Learn Programming Directly in VS Code" />
      <meta property="twitter:description" content="Interactive programming courses that run directly in VS Code. Learn coding with hands-on practice in a real development environment." />
      <meta property="twitter:image" content="https://codeswithvic.com/images/twitter-image.jpg" />

      {/* Favicon */}
      <link rel="icon" href="/favicon.ico" />
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <link rel="manifest" href="/site.webmanifest" />

      {/* Canonical */}
      <link rel="canonical" href="https://codeswithvic.com/" />

      {/* Structured Data - Organization */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "CodesWithVic",
          "url": "https://codeswithvic.com",
          "logo": "https://codeswithvic.com/logo.png",
          "sameAs": [
            "https://twitter.com/codeswithvic",
            "https://www.linkedin.com/company/codeswithvic",
            "https://github.com/codeswithvic"
          ],
          "contactPoint": {
            "@type": "ContactPoint",
            "email": "hello@codeswithvic.com",
            "contactType": "customer service"
          }
        })}
      </script>

      {/* Structured Data - WebSite */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "WebSite",
          "url": "https://codeswithvic.com",
          "name": "CodesWithVic",
          "description": "Interactive programming courses that run directly in VS Code",
          "potentialAction": {
            "@type": "SearchAction",
            "target": "https://codeswithvic.com/search?q={search_term_string}",
            "query-input": "required name=search_term_string"
          }
        })}
      </script>

      {/* Structured Data - Course */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Course",
          "name": "Javascript & NodeJS",
          "description": "The perfect blend to build scalable systems.",
          "provider": {
            "@type": "Organization",
            "name": "CodesWithVic",
            "sameAs": "https://codeswithvic.com/"
          },
          "offers": {
            "@type": "Offer",
            "price": "50",
            "priceCurrency": "USD",
            "availability": "https://schema.org/InStock",
            "url": "https://codeswithvic.com/courses/javascript-nodejs"
          },
          "hasCourseInstance": [
            {
              "@type": "CourseInstance",
              "name": "Javascript & NodeJS",
              "courseMode": "online",
              "startDate": "2024-01-01",
              "location": {
                "@type": "VirtualLocation",
                "url": "https://codeswithvic.com/courses/javascript-nodejs"
              }
            }
          ]
        })}
      </script>
    </Helmet>
  );
};

export default DefaultSEO;