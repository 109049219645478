import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Landing = () => {
  const [message, setMessage] = useState("Processing authentication...");
  const navigate = useNavigate();
  const isPopup = window.opener !== null;

  useEffect(() => {
    try {
      // Get token from URL
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get('token');
      console.log("Token from URL:", token);
      console.log("Window opener exists:", !!window.opener);

      if (!token) {
        console.error("No token found in URL");
        setMessage('Authentication failed: No token found in URL.');
        return;
      }

      if (window.opener) {
        // If opened in a popup, send message to parent window
        try {
          const targetOrigin = window.opener.location.origin;
          window.opener.postMessage({ token }, targetOrigin);
          setTimeout(() => window.close(), 1000);
        } catch (postError) {
          setMessage(`Authentication error: ${postError.message}`);
        }
      } else {
        // If not opened as popup, store token and redirect
        localStorage.setItem('authToken', token);

        // Show success message briefly before redirect
        setMessage("Authentication successful! Redirecting...");
        window.close();
        // setTimeout(() => {
        //   //close the modal
        //   window.close();
        // }, 1000);
      }
    } catch (error) {
      console.error("Unexpected error in authentication flow:", error);
      setMessage(`Unexpected error: ${error.message}`);
    }
  }, [navigate]);

  return (
    <div style={{ textAlign: "center", fontFamily: "Arial, sans-serif", marginTop: "50px" }}>
      <h2>Authentication</h2>
      <p>{message}</p>
    </div>
  );
};

export default Landing;