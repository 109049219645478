// React Basic and Bootstrap
import React from "react";
import { Container, Row, } from "reactstrap";

//Import Components
import CourseBox from "./course-box";
import SectionTitle from "../../components/Shared/SectionTitle";

const Services = () => {
  return (
    <React.Fragment>
      <section className="section" style={{ padding: '30px',}} id="courses">
        <Container>
          {/* section title */}
          <SectionTitle
            title="Unlock Your Potential with Our Courses!"
            desc=" From beginner to Pro!"
          />

            <Row>
              {/* coursebox */}
              <CourseBox  />
            </Row>
          </Container>
      </section>
    </React.Fragment>
  );
};

export default Services;
