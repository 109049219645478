import React, { Component, Suspense } from "react";
import BackToTop from "./backToTop";
import withRouter from "../../common/data/withRouter";
import DefaultSEO from "../SEO/DefaultSEO";

// Layout Components
const Topbar = React.lazy(() => import("./Topbar"));
const NavbarPage = React.lazy(() =>
  import("../../pages/Saas Onepage/NavbarPage")
);
const Footer = React.lazy(() => import("./Footer"));
const FooterWithoutMenuLightSocialOnly = React.lazy(() =>
  import("./FooterWithoutMenuLightSocialOnly")
);

const Loader = () => {
  return (
    <div id="preloader">
      <div id="status">
        <div className="spinner">
          <div className="double-bounce1"></div>
          <div className="double-bounce2"></div>
        </div>
      </div>
    </div>
  );
};

class Layout extends Component {
  render() {
    return (
      <React.Fragment>
        <DefaultSEO />
        <Suspense fallback={Loader()}>
          {this.props.router.location.pathname === "/index-onepage" ? (
            <NavbarPage />
          ) : (
            <Topbar tagline={null} hasDarkTopBar={this.props.hasDarkTopBar} />
          )}

          {this.props.children}
          {(() => {
            if (
              this.props.router.location.pathname === "/index-job"
            ) {
              return <Footer isLight={true} />;
            } else if (
              this.props.router.location.pathname === "/footer-layouts"
            ) {
              return (
                <>

                </>
              );
            } else if (
              this.props.router.location.pathname === "/index-personal"
            ) {
              return <FooterWithoutMenuLightSocialOnly className="" />;
            }
            else {
              return <Footer />;
            }
          })()}

          <BackToTop />
        </Suspense>

      </React.Fragment>
    );
  }
}

export default withRouter(Layout);
